import { Link } from 'gatsby';
import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import MobileNav from '~/components/Global/MobileNav';
import Nav from '~/components/Global/Nav';
import Logo from '~/images/logo.svg';

const Header: FunctionComponent = (): ReactElement => {
  const headerRef = useRef(null);
  const [isNavVisible, setNavVisibility] = useState(false);
  const [navScrolled, setNavScrolled] = useState(false);

  const toggleNav = () => {
    setNavVisibility(!isNavVisible);
  };

  useEffect(() => {
    const scrollListener = document.addEventListener(
      'scroll',
      () => {
        const sticky = headerRef.current;
        if (!sticky) return;
        setNavScrolled(
          document.body.scrollTop > 0 || document.documentElement.scrollTop > 0
        );
      },
      { passive: true }
    );

    return () => document.removeEventListener('scroll', scrollListener);
  }, []);

  return (
    <>
      {isNavVisible ? <MobileNav /> : null}

      <header
        id="sticky-header"
        ref={headerRef}
        className="fixed inset-x-0 top-0"
      >
        <div
          className={`relative header-wrapper ${
            navScrolled || isNavVisible ? 'py-4 nav-scrolled' : 'py-4 sm:py-6'
          }`}
        >
          <div className="container">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <Link to="/">
                  <Logo />
                </Link>
                <Nav />
              </div>

              <div className="hidden lg:flex lg:items-center">
                <a href="#login" className="btn alt">
                  Login
                </a>
                <Link
                  to="https://poweredbypercent.zendesk.com/hc/en-us"
                  className="ml-2 btn"
                >
                  Support
                </Link>
              </div>

              <div className="flex items-center mob-nav-toggle lg:hidden text-red">
                <div
                  className={`nav-icon ${isNavVisible === true ? 'open' : ''}`}
                  onClick={toggleNav}
                >
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>{' '}
      </header>
      <div className="header-height" />
    </>
  );
};

export default Header;
